import axios, { AxiosPromise } from 'axios';
import qs from 'qs';

export interface ApiCountryCode {
    id: number,
    parent_id: number,
    code: string,
    full: string,
    name: string,
}

export default {
    get: (countryCodeId: number): AxiosPromise<ApiCountryCode> => {
        return axios.get(`country-codes/${countryCodeId}`);
    },
    list: (query?: { [key: string]: any }): AxiosPromise<ApiCountryCode[]> => {
        return axios.get(`country-codes?${qs.stringify({
            ...query,
        })}`);
    },
}
