import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAltFastest, faBars, faTags, faUser, faFolderOpen, faSunCloud, faSun } from '@fortawesome/pro-light-svg-icons';
import { Menu, Segment } from '../../ApuKit';
import { useWindowResize } from '../../ApuKit/lib/hooks';
import Logo from '../../components/Logo';
import styles from './styles.module.scss';
import { AppState } from '../../store';

export const collapse = (state?: boolean) => {
    dispatchEvent(new CustomEvent('toggle-drawer', { detail: { state }}));
}

export const useCollapse = () => {
    const [ isCollapsed, setIsCollapsed ] = useState(false);

    useEffect(() => {
        const handleCollapse = (e: any) => {
            if (e.detail && e.detail.state !== undefined) {
                setIsCollapsed(e.detail.state);
            } else {
                setIsCollapsed(!isCollapsed);
            }
        };
        window.addEventListener('toggle-drawer', handleCollapse);
        return () => window.removeEventListener('toggle-drawer', handleCollapse);
    });

    return isCollapsed;
}

export default () => {
    const isCollapsed = useCollapse();
    const location = useLocation();
    const profile = useSelector((state: AppState) => state.auth.profile);
    const windowCollapse = () => collapse(window.innerWidth <= 992);
    useWindowResize(windowCollapse, 100);
    useEffect(windowCollapse, []);

    return (
        <Segment
            className={[
                styles.wrapper,
                isCollapsed && styles.isCollapsed,
            ].join(' ')}
            padding="none"
        >
            <div className={styles.base}>
                <div className={styles.logo}>
                    <FontAwesomeIcon icon={faBars} onClick={() => collapse()} />
                    <Logo />
                </div>
                <Menu>
                    <Menu.Item
                        active={location.pathname.includes('/preview')}
                        className={styles.menuItem}
                        content="Preview"
                        href="/preview"
                        icon={faTachometerAltFastest}
                    />
                </Menu>

                {profile && ['Administrator', 'Campagne manager', 'Editor'].includes(profile?.role) && <Menu>
                    <Menu.Header>Campagnes</Menu.Header>
                    <Menu.Item
                        active={location.pathname.includes('/campaigns')}
                        className={styles.menuItem}
                        content="Overzicht"
                        href="/campaigns"
                        icon={faTags}
                    />
                    <Menu.Item
                        active={location.pathname.includes('/assets')}
                        className={styles.menuItem}
                        content="Bestanden"
                        href="/assets"
                        icon={faFolderOpen}
                    />
                </Menu>}

                {profile?.role === 'Administrator' && <Menu>
                    <Menu.Header>Beheer</Menu.Header>
                    <Menu.Item
                        active={location.pathname.includes('/weather-data')}
                        className={styles.menuItem}
                        content="Weer API"
                        href="/weather-data"
                        icon={faSun}
                    />
                    <Menu.Item
                        active={location.pathname.includes('/users')}
                        className={styles.menuItem}
                        content="Gebruikers"
                        href="/users"
                        icon={faUser}
                    />
                </Menu>}
            </div>
        </Segment>
    );
}
