import { faPlus, faPencil, faTrashAlt, faTemperatureHot, faSnowflakes, faTimes, faRetweet } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiWeatherData } from '../../api/weatherdata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ weatherData, setWeatherData ] = useState<ApiWeatherData[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listWeatherData({ query, page, }).then(({ data }) => {
            setMeta(data.meta);
            setWeatherData(data.data);
            setIsLoading(false);
        });
    }

    const refreshData = (weatherDataId: number): void => {
        api.getWeatherData(weatherDataId, true).then(() => {
            toast('Data bijgewerkt');
            fetch();
        });
    }

    const deleteWeatherData = (weatherDataId: number): void => {
        api.deleteWeatherData(weatherDataId).then(() => {
            fetch();
            toast('Gebruiker succesvol verwijderd');
        });
    }

    return (<>
        <PageHeader title="Locaties" breadcrumb={{'/weather-data': 'Weer data'}}>
            <Button
                href="/weather-data/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment card isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Locatie
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Type
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            &deg;C
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            align="right"
                            collapsing
                            icon={faSnowflakes}
                        />
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {weatherData.length > 0 ? weatherData.map((weatherData, index) => (
                        <Table.Row key={`row-${weatherData.id}`}>
                            <Table.Cell>
                                <Link to={`/weather-data/${weatherData.id}/edit`}>
                                    {weatherData.location}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {weatherData.type}
                            </Table.Cell>
                            <Table.Cell
                                align="right"
                                collapsing
                            >
                                {weatherData.temp}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {weatherData.checkForSnow ? weatherData.snowfall : <FontAwesomeIcon icon={faTimes} className="error" />}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/weather-data/${weatherData.id}/edit`}
                                    />
                                    <Menu.Item
                                        icon={faRetweet}
                                        content="Data verversen"
                                        onClick={() => refreshData(weatherData.id)}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Weet je zeker dat je deze locatie wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden en de gegevens zullen niet meer beschikbaar zijn in campagnes"
                                        onConfirm={() => deleteWeatherData(weatherData.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}
