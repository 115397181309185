import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, Form, PageHeader, Segment, toast } from '../../ApuKit';
import api from '../../api';
import { ApiWeatherData } from '../../api/weatherdata';

interface WeatherDataEditProps extends RouteComponentProps<{ id?: string }> {
}

const WeatherDataEditView: FC<WeatherDataEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ weatherData, setWeatherData ] = useState<Partial<ApiWeatherData>>({});

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getWeatherData(parseInt(id)).then(({ data }) => {
                setWeatherData(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setWeatherData({
            ...weatherData,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putWeatherData(weatherData).then(() => {
            setIsLoading(false);
            toast('Locatie succesvol opgeslagen');
            history.push('/weather-data');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/weatherData': 'Gebruikers',
                [`/weatherData/${id ? `${id}/edit` : 'create'}`]: id ? weatherData.location || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} locatie`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment card isLoading={isLoading}>
                <Form.Input
                    error={errors.location}
                    label="Locatie"
                    name="location"
                    onChange={handleInput}
                    required
                    value={weatherData.location || ''}
                />
                <Form.Input
                    label="Zoekterm (als locatie niet voldoende is)"
                    name="searchTerm"
                    onChange={handleInput}
                    value={weatherData.searchTerm || ''}
                />
                <div style={{ marginBottom: '1rem' }}>
                    <Checkbox
                        checked={weatherData.checkForSnow}
                        label="Haal ook sneeuwhoogte op"
                        onChange={({ checked }: any) => handleInput({ name: 'checkForSnow', value: checked })}
                    />
                </div>
                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/weather-data"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>
    </>);
}

export default WeatherDataEditView;
