import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiWeatherData {
    id: number,
    location: string,
    searchTerm?: string,
    temp: number,
    type: string,
    snowfall: number,
    checkForSnow: boolean,
}

export default {
    delete: (weatherDataId: number): AxiosPromise => {
        return axios.delete(`weather-data/${weatherDataId}`);
    },
    get: (weatherDataId: number, refresh?: boolean): AxiosPromise<ApiWeatherData> => {
        return axios.get(`weather-data/${weatherDataId}${refresh ? '?refresh=1' : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiWeatherData[]>> => {
        return axios.get(`weather-data?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (weatherData: Partial<ApiWeatherData>): AxiosPromise<ApiWeatherData> => {
        return axios.post(`weather-data${weatherData.id ? `/${weatherData.id}` : ''}`, weatherData);
    },
}
