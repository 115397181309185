import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiUser {
    id: number,
    username: string,
    emailAddress: string,
    role: string,
}

export default {
    delete: (userId: number | string): AxiosPromise => {
        return axios.delete(`users/${userId}`);
    },
    get: (userId: number): AxiosPromise<ApiUser> => {
        return axios.get(`users/${userId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiUser[]>> => {
        return axios.get(`users?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (user: Partial<ApiUser>): AxiosPromise<ApiUser> => {
        return axios.post(`users${user.id ? `/${user.id}` : ''}`, user);
    },
    resetPassword: (userId: number): AxiosPromise => {
        return axios.post(`users/${userId}/reset-password`);
    }
}
