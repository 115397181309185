import { faRetweet, faShareAlt } from '@fortawesome/pro-light-svg-icons';
import { find, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import api, { request } from '../../api';
import { ApiCampaign } from '../../api/campaign';
import { Button, Dropdown, DropdownOption, Form, Grid, Input, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import ImageSelector from './ImageSelector';
import styles from './styles.module.scss';

//!!STUB!!
const defaultIcons = [
    'bed',
    'flight',
    'entertainment',
    'all-inclusive',
    'bus',
    'car',
    'transfer',
    'biggest-selection',
];

interface SheetRow {
    ID: string,
    reportingLabel: string,
    version: string,
    startDate: string,
    endDate: string,
    isActive: string,
    isDefault: string,
    size: string,
    weights: string,
    Dynamic_targeting_key: string,
    Country?: string,
    Region?: string,
    DepartureDate?: string,
    Airport?: string,
    PartyComposition?: string,
    Campaign: string,
    copy1: string,
    copy2: string,
    copy3: string,
    cta: string,
    pricePre: string,
    price: string,
    priceAfter: string,
    image1: string,
    icon1: string,
    icon2: string,
    icon3: string,
    exitURL: string,
    additionalTagging: string,
    landingspage: string,
}

const emptyRow: SheetRow = {
    ID: '',
    reportingLabel: '',
    version: '',
    startDate: '',
    endDate: '',
    isActive: '',
    isDefault: '',
    size: '',
    weights: '',
    Dynamic_targeting_key: '',
    Campaign: '',
    copy1: '',
    copy2: '',
    copy3: '',
    cta: '',
    pricePre: '',
    price: '',
    priceAfter: '',
    image1: '',
    icon1: '',
    icon2: '',
    icon3: '',
    exitURL: '',
    additionalTagging: '',
    landingspage: '',
}

export default (): JSX.Element => {
    const [ copyOptions, setCopyOptions ] = useState<DropdownOption[]>([]);
    const [ campaignOptions, setCampaignOptions ] = useState<DropdownOption[]>();
    const [ campaign, setCampaign ] = useState<ApiCampaign>();
    const [ activeDimension, setActiveDimension ] = useState<string>('');
    const [ activeTarget, setActiveTarget ] = useState<string>('');
    const [ content, setContent ] = useState<any>({});
    const [ rows, setRows ] = useState<SheetRow[]>([]);
    const [ targets, setTargets ] = useState<string[]>([]);
    const [ dtk, setDtk ] = useState<string>('');
    const [ showAll, setShowAll ] = useState<boolean>(false);
    const [ fase , setFase ] = useState<string>('Think');
    const [ links, setLinks ] = useState<any[]>([]);

    const getKeyFromTarget = (target: string): any => {
        let query: any = {
            Dynamic_targeting_key: target,
        };

        if (fase === 'Do') {
            const at = target.split(' | ');

            query = {
                Country: at[0] || '',
                Region: at[1] || '',
                DepartureDate: at[2] || '',
                Airport: at[3] || '',
                PartyComposition: at[4] || '',
            };
            console.log(query);
        };

        return query;
    }

    const fetchCampaignOptions = () => {
        api.listCampaigns().then(({ data }) => {
            setCampaignOptions(data.data.map((o) => ({
                text: `${o.code.code} ${o.name}`,
                value: o.id,
            })));
        });
    }
    useEffect(() => fetchCampaignOptions(), []);

    useEffect(() => {
        if (!campaign) return;
        const options: DropdownOption[] = [];

        targets.filter(t => t !== 'nieuw').map((t) => {
            campaign.dimensions.map((d) => {
                options.push({
                    text: `${t} - ${d}`,
                    value: [t, d],
                });
            });
        });

        setCopyOptions(options);
    }, [campaign, targets]);

    const getRows = () => {
        if (!campaign) return;
        // setActiveTarget('');
        request(`google/${campaign?.id}/rows/${fase}`).then(({ data }) => {
            setRows(data.rows);
            setLinks(data.links);
        }).catch((err) => {
            window.location.href = err.response.data.errors.url;
        });
    }
    useEffect(getRows, [campaign, fase]);

    useEffect(() => {
        setActiveTarget('');
    }, [fase]);

    const getTargetBasedOnFase = (row: SheetRow): string => {
        if (fase === 'Do') {
            const combinedTarget: string[] = [];
            const check: any = {...row};
            
            [
                'Country',
                'Region',
                'DepartureDate',
                'Airport',
                'PartyComposition',
            ].forEach((column: any) => {
                if (check[column]) {
                    combinedTarget.push(check[column]);
                }
            });

            if (combinedTarget.length <= 0) {
                return 'Geen target';
            }

            return combinedTarget.join(' | ');
        }

        return row.Dynamic_targeting_key || 'Geen target';
    }

    const updateTargets = () => {
        // setActiveTarget('');
        setTargets([
            ...uniq(rows
                .filter((o) => o.size === activeDimension)
                .map(getTargetBasedOnFase)),
            'nieuw'
        ]);
    }
    useEffect(updateTargets, [activeDimension, rows, fase]);

    const updateContent = async (overrideTarget?: undefined) => {
        if (!campaign || activeDimension === '' || activeTarget === '') {
            return;
        }

        const overrides = overrideTarget || [undefined, undefined];

        request(`google/${campaign?.id}/content/${activeDimension}`).then(({ data }) => {
            const row = find(rows, {
                size: overrides[1] || activeDimension,
                ...getKeyFromTarget(overrides[0] ||  activeTarget),
            }) as any;
            const values: any = {};

            Object.values(data.data.content).map((d: any, i) => {
                const key = Object.keys(data.data.content)[i] as any;
                d.sheetValue = row ? (row[key] || d.default) : d.default;
                d.characters = d.sheetValue.replace(/(<([^>]+)>)/gi, "").length;
                values[key] = d;
                return d;
            });
            
            setContent({
                ...data.data,
                edited: false,
                content: values,
            });
        });
    }
    useEffect(() => {
        updateContent();
    }, [activeDimension, activeTarget, campaign]);
    useEffect(() => {
        if (content && content.edited === false) {
            refresh();
        }
    }, [content]);

    const parseBread = (): {[key: string]: string} => {
        const bread: {[key: string]: string} = {'/preview': 'Preview'};
        
        if (campaign) {
            bread[`/assets/${campaign.id}`] = campaign.name;
        }

        return bread;
    }

    const loadCampaign = (campaignId: number): void => {
        api.getCampaign(campaignId).then(({ data }) => {
            setActiveDimension(data.dimensions[0]);
            setCampaign(data);
        });
    }

    const makeQuery = () => {
        let query = '&';
        Object.keys(content.content).map((key) => {
            const value = content.content[key].sheetValue
            query += `${key}=${encodeURIComponent(value.replace('<p>', '').replace('</p>', ''))}&`;
        });
        return query;
    }

    const refresh = () => {
        const query = makeQuery();

        const iframe = document.querySelector(`.previewframe-${activeDimension}`) as HTMLIFrameElement;
        if (iframe) {
            iframe.src = `${iframe.src}?start=1${query}`;
        }
    }

    const refreshAll = () => {
        if (!campaign || activeTarget === '' || activeTarget === 'nieuw') return;
        campaign.dimensions.map((size) => {
            const row = find(rows, { size, ...getKeyFromTarget(activeTarget),}) as any;
            
            let query = '&';
            Object.keys(content.content).map((key) => {
                const value = row[key] || '';
                query += `${key}=${encodeURIComponent(value.replace('<p>', '').replace('</p>', ''))}&`;
            });

            const iframe = document.querySelector(`.previewframe-${size}`) as HTMLIFrameElement;
            if (iframe) {
                iframe.src = `${iframe.src}?start=1${query}`;
            }
        });
    }
    useEffect(() => {
        if (showAll) {
            refreshAll();
        }
    }, [showAll, activeTarget]);

    const handleInput = (index: number, value: string, editor?: any) => {
        if (!content || !content.content) return;
        const key = Object.keys(content.content)[index];
        const max = content.content[key].max;
        let mutableValue = value;

        if (max && !editor) {
            mutableValue = value.substring(0, max);
        }

        setContent({
            ...content,
            edited: true,
            content: {
                ...content.content,
                [key]: {
                    ...content.content[key],
                    sheetValue: mutableValue,
                    characters: mutableValue.replace(/(<([^>]+)>)/gi, "").length,
                },
            },
        });
    }

    const save = () => {
        let row = find(rows, { size: activeDimension, ...getKeyFromTarget(activeTarget)}) as any;
        let isNew = false;

        if (!row) {
            row = Object.assign({}, emptyRow);
            if (activeTarget === 'nieuw') {
                row.size = activeDimension;
                if (fase !== 'Do') {
                    row.Dynamic_targeting_key = dtk;
                } else {
                    const split = dtk.split(' | ');
                    row.Country = split[0] || '';
                    row.Region = split[1] || '';
                    row.DepartureDate = split[2] || '';
                    row.Airport = split[3] || '';
                    row.PartyComposition = split[4] || '';
                }
                isNew = true;
            }
        }

        Object.values(content.content).map((d: any, i: number) => {
            row[Object.keys(content.content)[i]] = d.sheetValue.replace('<p>', '').replace('</p>', '');
            return d;
        });

        request.post(`google/${campaign?.id}/update/${fase}`, {
            row,
            isNew: isNew ? 1 : 0,
            query: makeQuery(),
        }).then(() => {
            getRows();
            toast('Campagne bijgewerkt');
        });
    }

    const copyShareLink = () => {
        const row = find(rows, { size: activeDimension, ...getKeyFromTarget(activeTarget)}) as any;
        
        if (row) {
            const link = find(links, { size: activeDimension, label: row.reportingLabel });
            if (link) {
                const input = document.createElement("input");
                input.type = "text";
                input.value = link.link;
                document.body.appendChild(input);
                input.select();
                document.execCommand("Copy");
                document.body.removeChild(input);
                toast('Link gekopieerd');
                return;
            }
        }
        
        alert('Sla de content eerst op');
    }

    return (<>
        <PageHeader title="Preview" breadcrumb={parseBread()} />
        <Grid.Row>
            <Grid.Column md={6}>
                {campaignOptions && (<div style={{ display: 'flex' }}>
                    <div style={{ width: 300, marginRight: 10 }}>
                        <Form.Dropdown
                            options={campaignOptions}
                            onChange={({ value }) => loadCampaign(value)}
                            placeholder="Kies een campagne"
                            value={campaign ? campaign.id : ''}
                        />
                    </div>
                    {campaign && <Form.Dropdown
                        options={[{
                            text: 'Think',
                            value: 'Think'
                        }, {
                            text: 'Do',
                            value: 'Do'
                        }, {
                            text: 'Interactive ad',
                            value: 'Interactive ad'
                        }]}
                        onChange={({ value }) => setFase(value)}
                        placeholder="Kies een fase"
                        value={fase}
                    />}
                </div>)}
            </Grid.Column>
        </Grid.Row>
        {campaign && (<>
            <Menu tabs style={{ marginBottom: '.5rem' }}>
                {campaign.dimensions.map((dim) => (
                    <Menu.Item
                        active={activeDimension === dim && !showAll}
                        onClick={() => { setActiveDimension(dim); setShowAll(false); }}
                        key={`tab-${dim}`}
                    >
                        {dim}
                    </Menu.Item>
                ))}
                <Menu.Item
                    active={showAll}
                    onClick={() => setShowAll(true)}
                >
                    Toon alles
                </Menu.Item>
            </Menu>
            {targets.length > 0 && (<div style={{ marginBottom: '1rem', maxWidth: 250 }}>
                <Dropdown
                    onChange={({ value }) => setActiveTarget(value)}
                    options={targets.map((o) => ({
                        text: o,
                        value: o,
                    }))}
                    placeholder="Selecteer een dynamic target"
                    value={activeTarget || ''}
                />
            </div>)}
            <div className={styles.previews}>
                {activeTarget !== '' && campaign.dimensions.filter(o => o === activeDimension || showAll).map((dim) => {
                    const [ width, height ] = dim.split('x').map(o => parseInt(o));
                    
                    return <div className={[styles.preview, showAll ? styles.previewAll : ''].join(' ')} key={dim}>
                        <div
                            style={{ display: 'inline-block' }}
                            onClick={() => {
                                if (showAll) {
                                    setActiveDimension(dim);
                                    setShowAll(false);
                                }
                            }}
                        >
                            <iframe
                                className={`previewframe-${dim}`}
                                src={`${campaign.base}/${dim}/index.html`}
                                style={{
                                    border: 0,
                                    height,
                                    width,
                                    pointerEvents: content.interactive ? 'inherit' : 'none',
                                }}
                            />
                        </div>
                        {!showAll && (
                            <Segment card padding="compact">
                                <div className={styles.header}>
                                    <h5>Contentopties</h5>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {targets.length > 0 && (
                                            <div style={{ marginRight: 5, minWidth: 250 }}>
                                                <Dropdown
                                                    onChange={({ value }) => updateContent(value)}
                                                    options={copyOptions}
                                                    placeholder="Overnemen van"
                                                    value=""
                                                />
                                            </div>
                                        )}
                                        <Button
                                            icon={faRetweet}
                                            onClick={refresh}
                                        />
                                        <Button
                                            icon={faShareAlt}
                                            onClick={copyShareLink}
                                        />
                                        <Button
                                            primary
                                            label="Opslaan"
                                            onClick={() => save()}
                                        />
                                    </div>
                                </div>

                                {content.content && <div className="">
                                    {activeTarget === 'nieuw' && (
                                        <div className={styles.contentOption}>
                                            <label style={{ display: 'block', marginBottom: 5 }}>
                                                Nieuwe dynamic targeting key
                                            </label>
                                            <Input
                                                onChange={({ value }: any) => setDtk(value)}
                                                value={dtk}
                                            />
                                        </div>
                                    )}
                                    {Object.values(content.content).map((field: any, index) => {
                                        let inputElem = <Input
                                            onChange={({ value }: any) => handleInput(index, value)}
                                            value={field.sheetValue}
                                        />

                                        if (field.type === 'select') {
                                            inputElem = <Dropdown
                                                onChange={({ value }: any) => handleInput(index, value)}
                                                options={defaultIcons.map((o) => ({
                                                    text: o,
                                                    value: o,
                                                }))}
                                                value={field.sheetValue || ''}
                                            />
                                        }

                                        if (field.type === 'multiselect') {
                                            inputElem = <Dropdown
                                                onChange={({ value }: any) => handleInput(index, value.join(','))}
                                                options={field.opts || []}
                                                value={field.sheetValue.split(',') || ''}
                                                multiple
                                            />
                                        }

                                        if (field.type === 'image') {
                                            inputElem = <ImageSelector
                                                onChange={(value: string) => handleInput(index, value)}
                                                value={field.sheetValue}
                                            />
                                        }

                                        return (
                                            <div
                                                className={styles.contentOption} 
                                                key={`${activeDimension}-${index}`}
                                            >
                                                <label style={{ display: 'block', marginBottom: 5 }}>
                                                    {field.label || Object.keys(content.content)[index]}
                                                </label>
                                                <div>
                                                    {field.options.includes('rich') ? (<Editor
                                                        apiKey="8vdxs5mwq7h7mt2ue991vtn94hdjv88w6q2gxydykw2yi3xl"
                                                        value={field.sheetValue}
                                                        init={{
                                                            height: 94,
                                                            menubar: false,
                                                            plugins: ['code', 'autoresize', 'wordcount', 'paste'],
                                                            toolbar: 'code | pastetext | bold italic',
                                                            content_style: 'body, p { margin: 0; padding: 4px 5px; font-size: 14px }',
                                                            autoresize_bottom_margin: 5,
                                                            force_br_newlines: true,
                                                            paste_as_text: true,
                                                        }}
                                                        onEditorChange={(c: string, editor) => handleInput(index, c, editor)}
                                                    />) : (inputElem)}
                                                    {field.max && (
                                                        <p
                                                            style={{
                                                                marginTop: 2,
                                                                fontStyle: 'italic',
                                                                color: field.characters > field.max ? '#dd0000' : '#526484',
                                                            }}
                                                        >
                                                            {field.characters} / { field.max} karakters.
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>}
                            </Segment>
                        )}
                    </div>
                })}
            </div>
        </>)}
    </>);
}
