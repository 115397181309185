import axios from 'axios';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Button, Form, Grid, Message, Segment, toast } from '../../../ApuKit';
import api from '../../../api';
import { setProfile } from '../../../reducers/auth';
import store from '../../../store';
import styles from '../LoginView/styles.module.scss';

const PasswordResetView: FC<RouteComponentProps> = ({ history }): JSX.Element => {
    const [ error, setError ] = useState<boolean>(false);
    const [ password2, setPassword2 ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    
    const login = (e: FormEvent): void => {
        e.preventDefault();
        setError(false);
        
        if (password !== password2) {
            setError(true);
        } else {
            axios.post('auth/reset-password', {
                ...qs.parse(window.location.search.substring(1)),
                password,
            }).then(({ data }) => {
                localStorage.setItem('accessToken', data.accessToken);
                toast('Wachtwoord succesvol ingesteld');
                store.dispatch(setProfile(data.profile));
                history.push('/');
            }).catch(() => {
                history.push('/');
            });
        }
    }

    return (<>
        <Grid className={styles.base}>
            <Grid.Row noGutters>
                <Grid.Column sm={6} md={5} style={{ marginBottom: 0 }}>
                    <Segment shadow style={{ height: '100vh' }}>
                        <div className={styles.container}>
                            <div className={styles.logo}>
                                <img src="/assets/logo.svg" alt="" />
                            </div>
                            {error && <Message
                                content="Wachtwoorden komen niet overeen"
                                error
                                onClose={() => setError(false)}
                            />}
                            <Form onSubmit={(e: FormEvent) => login(e)}>
                                <Form.Input
                                    error={error}
                                    label="Wachtwoord"
                                    onChange={({ value }: any) => setPassword(value)}
                                    placeholder="Vul je wachtwoord in"
                                    required
                                    type="password"
                                    value={password}
                                />
                                <Form.Input
                                    error={error}
                                    label="Herhaal wachtwoord"
                                    onChange={({ value }: any) => setPassword2(value)}
                                    placeholder="Herhaal je wachtwoord"
                                    required
                                    type="password"
                                    value={password2}
                                />
                                <Button
                                    block
                                    padding="default"
                                    primary
                                    type="submit"
                                >
                                    Wachtwoord instellen
                                </Button>
                                <Button
                                    block
                                    href="/login"
                                    link
                                    padding="default"
                                >
                                    Annuleren
                                </Button>
                            </Form>
                        </div>
                    </Segment>
                </Grid.Column>
                <Grid.Column
                    className={styles.backgroundImage}
                    isHidden={['xs']}
                    sm={6}
                    md={7}
                    style={{ marginBottom: 0 }}
                />
            </Grid.Row>
        </Grid>
    </>);
}

export default PasswordResetView;
