import { faRetweet } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import api from '../../../api';
import { ApiGoogleImage } from '../../../api/googleimage';
import { Button, Input, toast } from '../../../ApuKit';
import config from '../../../config.json';
import styles from './styles.module.scss';

interface ImagesSelectorProps {
    onChange: (newValue: string) => void,
    value: string,
}

const Thumb = ({ src }: { src: string }): JSX.Element => {
    return (
        <div
            className={styles.thumb}
            style={{
                backgroundImage: `url('https://lh3.googleusercontent.com/d/${src}?authuser=0')`,
            }}
        />
    );
}

const ImageSelector: FC<ImagesSelectorProps> = ({ onChange, value }): JSX.Element => {
    const [ selectedImage, setSelectedImage ] = useState<ApiGoogleImage>();
    const [ images, setImages ] = useState<ApiGoogleImage[]>([]);
    const [ open, setOpen ] = useState<boolean>(false);
    const [ query, setQuery ] = useState<string>('');

    const fetch = (): void => {
        api.listImages().then(({ data }) => {
            setImages(data.data);
        });
    }
    useEffect(() => {
        if (open && images.length <= 0) {
            fetch();
        }
    }, [open]);

    const handleSelection = (img: ApiGoogleImage): void => {
        onChange(img.googleId);
        setOpen(false);
    }

    const updateLibrary = (): void => {
        api.updateLibary().then(() => {
            fetch();
            toast('Bibliotheek bijgewerkt');
        });
    }

    useEffect(() => {
        setSelectedImage(images.filter(o => o.googleId === value)[0]);
    }, [value, images]);

    const search = (img: ApiGoogleImage): boolean => {
        return img.name.indexOf(query) !== -1;
    }

    return (
        <div className={styles.base}>
            <div className={styles.selection}>
                {selectedImage && <div>
                    <Thumb src={selectedImage.googleId} />
                    <span style={{ fontSize: 9 }}>
                        {selectedImage.name}
                    </span>
                </div>}
                <div style={{ marginTop: '.5rem', textAlign: 'center' }}>
                    <Button
                        label="Wijzigen"
                        onClick={() => setOpen(true)}
                    />
                </div>
            </div>
            <div>
                {open && <div className={styles.refresh}>
                    <Input
                        placeholder="Zoeken"
                        onChange={({ value }: any) => setQuery(value)}
                        value={query}
                    />
                    <Button
                        onClick={updateLibrary}
                        label="Bijwerken"
                        icon={faRetweet}
                    />
                </div>}
                <div className={styles.options} style={{ display: open ? 'flex' : 'none' }}>
                    {images.filter(search).map((img) => (
                        <div
                            className={[
                                styles.option,
                                selectedImage === img ? styles.selected : '',
                            ].join(' ')}
                            onClick={() => handleSelection(img)}
                        >
                            <div>
                                <Thumb src={img.googleId} />
                                <span style={{ fontSize: 9 }}>
                                    {img.name}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ImageSelector;
