import qs from 'qs';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '../../api';
import styles from './styles.module.scss';

const ShareView: FC = (): JSX.Element | null => {
    const params = useParams() as any;
    const [ shares, setShares ] = useState<any[]>();

    useEffect(() => {
        const query = qs.parse(window.location.search.substring(1));

        request.get(`share/${params.id || ''}${query.all ? '/all' : ''}`).then(({ data }) => {
            if (query.all) {
                setShares(data);
            } else {
                setShares([data]);
            }
        })
    }, [params]);

    if (!shares || shares.length <= 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            {shares.map((share, index) => (
                <div
                    key={`share-${index}`}
                    className={styles.frame}
                    style={{
                        height: parseInt(share.size[1]),
                        width: parseInt(share.size[0]),
                    }}
                >
                    <iframe
                        src={share.frame}
                        style={{
                            height: parseInt(share.size[1]),
                            width: parseInt(share.size[0]),
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

export default ShareView;
