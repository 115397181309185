import axios from 'axios';
import config from '../config.json';
import auth from './auth';
import campaign from './campaign';
import countrycode from './countrycode';
import googleimage from './googleimage';
import user from './user';
import weatherData from './weatherdata';

export const getToken = () => {
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = config.baseUrl;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,

    deleteCampaign: campaign.delete,
    getCampaign: campaign.get,
    listCampaignFiles: campaign.getFiles,
    listCampaigns: campaign.list,
    putCampaign: campaign.put,
    
    getCountryCode: countrycode.get,
    listCountryCodes: countrycode.list,
    
    listImages: googleimage.list,
    updateLibary: googleimage.update,

    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,
    resetUserPassword: user.resetPassword,

    deleteWeatherData: weatherData.delete,
    getWeatherData: weatherData.get,
    listWeatherData: weatherData.list,
    putWeatherData: weatherData.put,
};

export default api;
