import { find } from 'lodash';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, PageHeader, Progress, Segment, Table } from '../../ApuKit';
import api, { ApiMeta, request } from '../../api';
import { ApiCampaign } from '../../api/campaign';
import { faUpload } from '@fortawesome/pro-light-svg-icons';

interface AssetViewProps extends RouteComponentProps<{ cid?: string, dim?: string }> {
}

const AssetView: FC<AssetViewProps> = ({ match }) => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ campaign, setCampaign ] = useState<ApiCampaign|undefined>(undefined);
    const [ campaigns, setCampaigns ] = useState<ApiCampaign[]>([]);
    const [ dimension, setDimension ] = useState<string>('');
    const [ files, setFiles ] = useState<string[]>([]);
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ totalUploadProgress, setTotalUploadProgress ] = useState<number>(0);

    const setMatch = (): void => {
        if (match.params.cid) {
            setCampaign(find(campaigns, { id: parseInt(match.params.cid) }));
        } else {
            setCampaign(undefined);
        }
        setDimension(match.params.dim || '');
    }

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listCampaigns({ query, page, }).then(({ data }) => {
            setMeta(data.meta);
            setCampaigns(data.data);
            setIsLoading(false);
        });
    }

    const fetchFiles = (): void => {
        if (dimension && campaign) {
            api.listCampaignFiles(campaign?.id, dimension).then(({ data }) => {
                setFiles(data.files);
            });
        }
    }

    useEffect(setMatch, [campaigns, match]);
    useEffect(fetchFiles, [dimension]);

    const handleFileUpload = (e: any): void => {
        setIsUploading(true);
        uploadFile(e.target.files, 0, e.target.files.length);
    }

    const uploadFile = (files: FileList, index: number, max: number): void => {
        setTotalUploadProgress(index / max);
        setUploadProgress(0);

        if (!campaign || index >= max) {
            setIsUploading(false);
            return;
        }

        const fd = new FormData();
        fd.append('file', files[index]);

        request.post(`campaigns/${campaign.id}/upload-file/${dimension}`, fd, {
            onUploadProgress: function(progressEvent) {
                setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
        }).then(() => {
            fetchFiles();
            uploadFile(files, index + 1, max);
        });
    }

    const parseBread = (): {[key: string]: string} => {
        const bread: {[key: string]: string} = {'/assets': 'Bestanden'};

        if (campaign) {
            bread[`/assets/${campaign.id}`] = campaign.name;
            
            if (dimension) {
                bread[`/assets/${campaign.id}/${dimension}`] = dimension;
            }
        }

        return bread;
    }

    return (<>
        <PageHeader title="Bestanden" breadcrumb={parseBread()}>
            {dimension && <>
                <Button
                    icon={faUpload}
                    onClick={() => fileInputRef.current?.click()}
                />
                <input
                    multiple
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                />
            </>}
        </PageHeader>
        
        {isUploading && <Segment card>
            <Progress progress={uploadProgress} style={{ marginBottom: '.5rem' }} />
            <Progress progress={totalUploadProgress * 100} />
        </Segment>}

        <Segment card isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        {!campaign && <>
                            <Table.HeaderCell collapsing>
                                Code
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Campagne
                            </Table.HeaderCell>
                        </>}
                        {campaign && dimension === '' && (
                            <Table.HeaderCell>
                                Afmeting
                            </Table.HeaderCell>
                        )}
                    </Table.Row>
                </thead>
                <tbody>
                    {campaigns.length > 0 ? campaigns.map((c, index) => {
                        if (!campaign) {
                            return <Table.Row key={`row-${c.id}`}>
                                <Table.Cell collapsing>
                                    {c.code.full}
                                </Table.Cell>
                                <Table.Cell>
                                    <Link to={`/assets/${c.id}`}>
                                        {c.name}
                                    </Link>
                                </Table.Cell>
                            </Table.Row>
                        } else if (dimension === '') {
                            return c.dimensions.sort().map((d) => (
                                <Table.Row key={`row-${c.id}-${d}`}>
                                    <Table.Cell>
                                        <Link to={`/assets/${c.id}/${d}`}>
                                            {d}
                                        </Link>
                                    </Table.Cell>
                                </Table.Row>
                            ));
                        } else {
                            return files.map((file, index) => (
                                <Table.Row key={`row-${c.id}-${index}`}>
                                    <Table.Cell>
                                        {file}
                                    </Table.Cell>
                                </Table.Row>
                            ));
                        }
                    }) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default AssetView;
