import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, DropdownOption, Form, PageHeader, Segment, toast } from '../../ApuKit';
import api from '../../api';
import { ApiCampaign } from '../../api/campaign';

const statusOptions: DropdownOption[] = [{
    text: 'Actief',
    value: 1,
}, {
    text: 'Inactief',
    value: 0,
}]

interface CampaignEditProps extends RouteComponentProps<{ id?: string }> {
}

const CampaignEditView: FC<CampaignEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ campaign, setCampaign ] = useState<Partial<ApiCampaign>>({dimensions: []});

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getCampaign(parseInt(id)).then(({ data }) => {
                setCampaign(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCountryCodes({ query }).then(({ data }) => {
            callback(data.filter(o => o.parent_id).map((o) => ({
                text: o.full,
                value: o.id,
            })));
        });
    }

    const handleDimensions = (dimension: string, state: boolean): void => {
        let dimensions = [...(campaign.dimensions || [])];
        
        if (state) {
            dimensions.push(dimension);
        } else {
            dimensions = dimensions.filter(o => o !== dimension);
        }

        setCampaign({
            ...campaign,
            dimensions,
        });
    }

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setCampaign({
            ...campaign,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putCampaign(campaign).then(() => {
            setIsLoading(false);
            toast('Campagne succesvol opgeslagen');
            history.push('/campaigns');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/campaigns': 'Campagnes',
                [`/campaigns/${id ? `${id}/edit` : 'create'}`]: id ? `${campaign.code?.code} - ${campaign.name}` || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} campagne`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment card isLoading={isLoading}>
                <Form.Dropdown
                    label="Status"
                    name="isActive"
                    onChange={handleInput}
                    options={statusOptions}
                    value={campaign.isActive ? 1 : 0}
                />
                {!isLoading && <Form.Dropdown
                    autoComplete={handleAutoComplete}
                    clearable
                    error={errors.country_code_id}
                    defaultOption={campaign.code?.full}
                    label="Code"
                    name="countryCodeId"
                    onChange={handleInput}
                    placeholder="Kies een landcode"
                    required
                    value={campaign.countryCodeId || ''}
                />}
                <Form.Input
                    error={errors.name}
                    label="Titel"
                    name="name"
                    onChange={handleInput}
                    required
                    value={campaign.name || ''}
                />
                <Form.Group
                    label="Beschikbare afmetingen"
                    required
                >
                    {[
                        '120x600',
                        '160x600',
                        '300x250',
                        '300x600',
                        '320x100',
                        '320x240',
                        '336x280',
                        '728x90',
                        '970x250',
                        '930x180',
                    ].map((dim) => (
                        <Checkbox
                            checked={campaign.dimensions?.includes(dim)}
                            label={dim}
                            onChange={({ checked }: any) => handleDimensions(dim, checked)}
                        />
                    ))}
                </Form.Group>
                <Form.Input
                    label="Google sheet ID"
                    name="sheetId"
                    onChange={handleInput}
                    value={campaign.sheetId || ''}
                />
                <Form.Dropdown
                    options={[{
                        text: 'Think',
                        value: 'Think'
                    }, {
                        text: 'Do',
                        value: 'Do'
                    }, {
                        text: 'Interactive ad',
                        value: 'Interactive ad'
                    }]}
                    label="Werkblad"
                    name="worksheet"
                    onChange={handleInput}
                    value={campaign.worksheet || ''}
                />
                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/campaigns"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>
    </>);
}

export default CampaignEditView;
