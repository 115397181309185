import React, { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, DropdownOption, Form, PageHeader, Segment, toast, Grid } from '../../ApuKit';
import api from '../../api';
import { ApiUser } from '../../api/user';

interface UserEditProps extends RouteComponentProps<{ id?: string }> {
}

const UserEditView: FC<UserEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ user, setUser ] = useState<Partial<ApiUser>>({});

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getUser(parseInt(id)).then(({ data }) => {
                setUser(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setUser({
            ...user,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putUser(user).then(() => {
            setIsLoading(false);
            toast('Gebruiker succesvol opgeslagen');
            history.push('/users');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/users': 'Gebruikers',
                [`/users/${id ? `${id}/edit` : 'create'}`]: id ? user.username || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} gebruiker`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment card isLoading={isLoading}>
                <Form.Dropdown
                    label="Rol"
                    name="role"
                    onChange={handleInput}
                    options={[{
                        text: 'Administrator',
                        value: 'Administrator',
                    }, {
                        text: 'Campagne manager',
                        value: 'Campagne manager',
                    }, {
                        text: 'Editor',
                        value: 'Editor',
                    }, {
                        text: 'Previewer',
                        value: 'Previewer',
                    }]}
                    required
                    value={user.role}
                />
                <Form.Input
                    error={errors.username}
                    label="Naam"
                    name="username"
                    onChange={handleInput}
                    required
                    value={user.username || ''}
                />
                <Form.Input
                    error={errors.email_address}
                    label="E-mailadres"
                    name="emailAddress"
                    onChange={handleInput}
                    required
                    value={user.emailAddress || ''}
                />
                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/users"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>
    </>);
}

export default UserEditView;
