import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationCenter } from './ApuKit';
import Loader from './ApuKit/elements/Loader';
import api, { getToken } from './api';
import { AppState } from './store';
import Drawer, { useCollapse } from './modules/Drawer';
import TopBar from './modules/TopBar';
import styles from './styles.module.scss';
import LoginView from './views/Auth/LoginView';
import AssetView from './views/AssetView';
import PasswordResetView from './views/Auth/PasswordResetView';
import CampaignListView from './views/CampaignView';
import CampaignEditView from './views/CampaignView/Edit';
import PreviewView from './views/PreviewView';
import UserListView from './views/UserView';
import UserEditView from './views/UserView/Edit';
import WeatherDataListView from './views/WeatherDataView';
import WeatherDataEditView from './views/WeatherDataView/Edit';
import ShareView from './views/ShareView';
// import Socket from './Socket';

export default (): JSX.Element => {
    const isCollapsed = useCollapse();
    const [ isLoading, setIsLoading ] = useState(getToken() || false);
    const profile = useSelector((state: AppState) => state.auth.profile);
        
    useEffect(() => {
        api.getProfile().then(() => setIsLoading(false)).catch(() => {
            setIsLoading(false);
        });

        const theme = localStorage.getItem('theme') || 'into-the-light';
        document.getElementsByTagName('html')[0].classList.add(theme);
    }, []);

    return (
        <Router>
            {isLoading ? (
                <Loader />
            ) : profile ? (<>
                <TopBar />
                <Drawer />
                <main className={[styles.main, isCollapsed && styles.isCollapsed].join(' ')}>
                    <div>
                        <Switch>
                            <Route component={AssetView} exact path="/assets/:cid?/:dim?" />
                            <Route component={CampaignListView} exact path="/campaigns" />
                            <Route component={CampaignEditView} exact path="/campaigns/create" />
                            <Route component={CampaignEditView} exact path="/campaigns/:id/edit" />
                            <Route component={PreviewView} exact path="/preview" />
                            <Route component={UserListView} exact path="/users" />
                            <Route component={UserEditView} exact path="/users/create" />
                            <Route component={UserEditView} exact path="/users/:id/edit" />
                            <Route component={WeatherDataListView} exact path="/weather-data" />
                            <Route component={WeatherDataEditView} exact path="/weather-data/create" />
                            <Route component={WeatherDataEditView} exact path="/weather-data/:id/edit" />
                        </Switch>
                    </div>
                </main>
                {/* <Socket /> */}
            </>) : (
                <Switch>
                    <Route component={ShareView} exact path="/s/:id" />
                    <Route component={PasswordResetView} exact path="/reset-password" />
                    <Route component={LoginView} path="*" />
                </Switch>
            )}
            <NotificationCenter />
        </Router>
    );
}
