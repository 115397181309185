import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiGoogleImage {
    id: number,
    googleId: string,
    name: string,
}

export default {
    list: (query?: { [key: string]: any }): AxiosPromise<ApiPaginatedResponse<ApiGoogleImage[]>> => {
        return axios.get(`google/images?${qs.stringify(query)}`);
    },
    update: (): AxiosPromise => {
        return axios.post('google/images');
    },
}
