import axios from 'axios';
import { setProfile } from '../reducers/auth';
import store from '../store';

export interface ApiProfile {
    id: string,
    username: string,
    role: string,
}

export default {
    getProfile: (): Promise<ApiProfile> => {
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    login: (emailAddress: string, password: string): Promise<ApiProfile> => {
        return axios.post('auth/login', {
            emailAddress,
            password,
        }).then(({ data }: any) => {
            localStorage.setItem('accessToken', data.accessToken);
            return data.profile;
        });
    },
    logout: (): void => {
        localStorage.removeItem('accessToken');
        store.dispatch(setProfile());
    }
}
