import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';
import { ApiCountryCode } from './countrycode';

export interface ApiCampaign {
    id: number,
    base: string,
    code: ApiCountryCode,
    countryCodeId: number,
    dimensions: string[],
    name: string,
    isActive: boolean,
    sheetId?: string,
    worksheet?: string,
}

export default {
    delete: (campaignId: number | string): AxiosPromise => {
        return axios.delete(`campaigns/${campaignId}`);
    },
    get: (campaignId: number): AxiosPromise<ApiCampaign> => {
        return axios.get(`campaigns/${campaignId}`);
    },
    getFiles: (campaignId: number, dimension: string): AxiosPromise<{files: string[]}> => {
        return axios.get(`campaigns/${campaignId}/files/${dimension}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiCampaign[]>> => {
        return axios.get(`campaigns?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (campaign: Partial<ApiCampaign>): AxiosPromise<ApiCampaign> => {
        return axios.post(`campaigns${campaign.id ? `/${campaign.id}` : ''}`, campaign);
    },
    resetPassword: (campaignId: number): AxiosPromise => {
        return axios.post(`campaigns/${campaignId}/reset-password`);
    }
}
